<template>
	<BaseLoading v-if="edit.isLoading" is-full-page />
	<PromotionBannerSetsForm
		v-else
		:is-submitting="edit.isSubmitting"
		:default-data="edit.data"
		is-edit-mode
		@onSubmit="onUpdate"
	/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PromotionBannerSetsForm from '@/components/PromotionBannerSetsForm.vue';

export default {
	name: 'PromotionBannerSetsEdit',
	components: {
		PromotionBannerSetsForm,
	},

	computed: {
		...mapState('promotionBannerSets', {
			edit: 'edit',
		}),
	},

	async created() {
		await this.getPromotionBannerSet(this.$route.params.id);
	},

	methods: {
		...mapActions({
			getPromotionBannerSet: 'promotionBannerSets/getPromotionBannerSet',
			updatePromotionBannerSet: 'promotionBannerSets/updatePromotionBannerSet',
		}),

		async onUpdate(params) {
			const id = this.$route.params.id;
			this.updatePromotionBannerSet({ id, params });
		},
	},
};
</script>
